<template>
<div class="app-container">
  <el-steps :active="active" finish-status="success" align-center>
    <el-step title="Audiencia"></el-step>
    <el-step title="Mensaje"></el-step>
  </el-steps>
  <div v-if="active===0">
    <el-row class="margin-bottom-20" >
      <el-col :lg="11" :md="11" :sm="14" :xs="14" :offset="0">
        <el-input prefix-icon="el-icon-search" size="mini" placeholder="Buscar" v-model="searchForm.query" v-on:change="handleSearch()" clearable></el-input>
      </el-col>
      <el-button
        class="margin-left-20"
        @click.stop.prevent="handleSearch"
        type="primary"
        icon="el-icon-search"
        size="mini"
      >Buscar</el-button>
      <el-button
        @click.stop.prevent="handleCleanSearch"
        type="warning"
        icon="el-icon-delete"
        size="mini"
      >Limpiar Busqueda</el-button>
      <el-button
        @click.stop.prevent="handleClean"
        type="warning"
        icon="el-icon-delete"
        size="mini"
      >Limpiar Filtros</el-button>
    </el-row>
     <el-row :gutter="20">
       <el-tabs type="card" size="mini">
            <el-tab-pane>
              <span slot="label"><i class="el-icon-document"></i> General</span>
              <el-col :span="4">
                <div class="grid-content bg-purple">
                  <span class=" margin-filter">Tipo de mensaje</span> <br>
                  <el-select v-model="filterForm.filterTypeMessage" filterable v-on:change="handleSearchFilter()" size="mini" placeholder="Personalizado">
                    <el-option
                      v-for="itemMessage in typeMessageOptions"
                      :key="itemMessage.value"
                      :label="itemMessage.label"
                      :value="itemMessage.value">
                    </el-option>
                  </el-select>
                </div>
              </el-col>
              <el-col :span="5">
                <div class="grid-content bg-purple">
                  <span class=" margin-filter">Puesto</span> <br>
                  <el-select v-model="filterForm.filterJob" filterable clearable v-on:change="handleSearchFilter()" size="mini" placeholder="Puesto">
                    <el-option
                      v-for="itemJob in listJob"
                      :key="itemJob.idJob"
                      :label="itemJob.job"
                      :value="itemJob.idJob">
                      <span style="float: left">{{ itemJob.job }}</span>
                        <span style="float: right; color: #8492a6; font-size: 13px">{{ itemJob.idJob }}</span>
                    </el-option>
                  </el-select>
                </div>
              </el-col>
               <el-col :span="5">
                <div class="grid-content bg-purple">
                  <span class=" margin-filter">Lugar de trabajo</span> <br>
                  <el-select v-model="filterForm.filterWorkplace" filterable clearable v-on:change="handleSearchFilter()" size="mini" placeholder="Lugar de Trabajo">
                    <el-option
                      v-for="itemWork in listWorkPlace"
                      :key="itemWork.idWorkPlace"
                      :label="itemWork.workPlace"
                      :value="itemWork.idWorkPlace">
                      <span style="float: left">{{ itemWork.workPlace }}</span>
                      <span style="float: right; color: #8492a6; font-size: 13px">{{ itemWork.idWorkPlace }}</span>
                    </el-option>
                  </el-select>
                </div>
              </el-col>
              <el-col :span="5">
                <div class="grid-content bg-purple">
                  <span class=" margin-filter">Unidad de trabajo</span> <br>
                  <el-select v-model="filterForm.filterUnit" filterable clearable v-on:change="handleSearchFilter()" size="mini" placeholder="Unidad de Trabajo">
                    <el-option
                      v-for="itemUnit in listUnitPlace"
                      :key="itemUnit.idUnit"
                      :label="itemUnit.unit"
                      :value="itemUnit.idUnit">
                      <span style="float: left">{{ itemUnit.unit }}</span>
                        <span style="float: right; color: #8492a6; font-size: 13px">{{ itemUnit.idUnit }}</span>
                    </el-option>
                  </el-select>
                </div>
              </el-col>
              <el-col :span="5">
                <div class="grid-content bg-purple">
                  <span class=" margin-filter">Dirección</span> <br>
                  <el-select v-model="filterForm.filterTeam" filterable clearable v-on:change="handleSearchFilter()" size="mini" placeholder="CUADRILLAS CUAUTLA">
                    <el-option
                      v-for="itemTeam in listTeamid"
                      :key="itemTeam.idTeam"
                      :label="itemTeam.team"
                      :value="itemTeam.idTeam">
                      <span style="float: left">{{ itemTeam.team }}</span>
                        <span style="float: right; color: #8492a6; font-size: 13px">{{ itemTeam.idTeam }}</span>
                    </el-option>
                  </el-select>
                </div>
              </el-col>
            </el-tab-pane>
            <!-- Fechas -->
            <el-tab-pane >
              <span slot="label"><i class="el-icon-date"></i> Fechas</span>
              <el-col :span="9">
                <div class="grid-content bg-purple">
                  <span class=" margin-filter">Antiguedad</span>
                  <el-date-picker
                    v-model="filterDateAntiquity"
                    v-on:change="handleFilterDateAntiquity()"
                    size="mini"
                    type="daterange"
                    align="right"
                    format="dd-MM-yyyy"
                    unlink-panels
                    range-separator="a"
                    start-placeholder="Fecha inicial"
                    end-placeholder="Fecha final"
                    :picker-options="pickerOptions">
                  </el-date-picker>
                </div>
              </el-col>
              <el-col :sm="24" :md="10" :offset="1" >
                 <div class="grid-content bg-purple">
                    <span class=" margin-filter">Fecha de Nacimiento</span>
                  <el-date-picker
                    v-model="filterBirthDate"
                    v-on:change="handleFilterBirthDate()"
                    size="mini"
                    type="daterange"
                    align="right"
                    format="dd-MM-yyyy"
                    unlink-panels
                    range-separator="a"
                    start-placeholder="Fecha inicial"
                    end-placeholder="Fecha final"
                    :picker-options="pickerOptions">
                  </el-date-picker>
                </div>
              </el-col>
            </el-tab-pane>
            <!-- Usuarios -->
            <el-tab-pane>
              <span slot="label"><i class="el-icon-user"></i> Usuarios</span>
              <el-col :span="11">
                <el-table
                  :data="filterForm.filterIncludeUsersId"
                  style="width: 100%"
                  size="mini"
                  class="padding-0"
                  max-height="150">
                  <el-table-column label="Incluidos">
                    <el-table-column label="Id Usuario" width="200">
                      <template slot-scope="scope">
                        <span style="margin-left: 10px">{{ scope.row._id }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="Correo" width="200">
                      <template slot-scope="scope">
                        <span style="margin-left: 10px">{{ scope.row.email }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="Acción" >
                      <template slot-scope="scope">
                        <el-button
                          size="mini"
                          type="text"
                          round
                          @click="handleDeleteIncluder(scope.$index, scope.row)">Quitar</el-button>
                      </template>
                    </el-table-column>
                  </el-table-column>
                </el-table>
              </el-col>
              <el-col style="margin-left: 10px" :span="12" >
                <el-table
                  :data="filterForm.filterExcludeUsersId"
                  style="width: 100%"
                  size="mini"
                  class="padding-0"
                  max-height="150">
                  <el-table-column label="Excluidos">
                    <el-table-column label="Id user" width="200">
                      <template slot-scope="scope">
                        <span style="margin-left: 10px">{{ scope.row._id }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="Nombre" width="200">
                      <template slot-scope="scope">
                        <span style="margin-left: 10px">{{ scope.row.email }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="Acción">
                      <template slot-scope="scope">
                        <el-button
                          size="mini"
                          type="text"
                          round
                          @click="handleDeleteExcluder(scope.$index, scope.row)">Quitar</el-button>
                      </template>
                    </el-table-column>
                  </el-table-column>
                </el-table>
              </el-col>
              <el-col :span="8" :offset="7">
                <el-card class="box-card">
                  <div class="text item">
                    <span>¿Solo enviar a los usuarios de la lista de incluidos?  </span>
                    <el-switch
                      v-model="filterForm.onlyListIncludedUser"
                      active-text="si"
                      inactive-text="No">
                    </el-switch>
                  </div>
                </el-card>
              </el-col>
            </el-tab-pane>
          </el-tabs>
    </el-row>
    <el-row type="flex" justify="between">
      <el-col :span="3" :offset="5">
        <el-button
          @click.stop.prevent="goBack"
          size="mini"
          type="primary"
          icon="el-icon-back"
        >Regresar </el-button>
      </el-col>
      <el-col :span="2" :offset="9">
        <el-button
          @click.stop.prevent="goNext"
          size="mini"
          type="primary"
          :loading="loading"
        >Continuar <i class="el-icon-arrow-right el-icon-right"></i></el-button>
      </el-col>
    </el-row>
    <el-row type="flex" justify="end">
        <el-pagination small @size-change="handleChangeItemPage"  :page-sizes="[25, 50, 100, 200]"  :page-size="25" layout="total,sizes,prev, pager, next" @current-change="handleCurrentChange"
          :total="parseInt(totalPages)">
        </el-pagination>
    </el-row>
    <el-table
      v-loading="loading"
      :data="list"
      element-loading-text="Loading"
      highlight-current-row
      style="width: 100%"
      size="mini"
    >
     <el-table-column type="expand">
        <template slot-scope="scope">
          <span>ID:  {{ scope.row._id }}</span><br>
          <span>ID Usuario:  {{ scope.row.idEmployed }}</span><br>
          <span>Nombre:  {{ scope.row.name }}</span><br>
          <span>Puesto:  {{ scope.row.job }}</span><br>
          <span>Correo:   {{ scope.row.email }}</span><br>
          <span>Correo verificado:   {{ scope.row.isEmailVerified?'Verificado':'No verificado', }}</span><br>
          <span>Telefono:   {{ scope.row.phoneCelular }}</span><br>
          <span>Telefono verificado:   {{ scope.row.isPhoneVerified?'Verificado':'No verificado', }}</span><br>
          <span>RFC:   {{ scope.row.RFC }}</span><br>
          <span>CURP:  {{ scope.row.CURP }}</span><br>
          <span>NSS:   {{ scope.row.NSS }}</span><br>
          <span>Días de Vacaciones:   {{ scope.row.vacationDays }}</span><br>
          <span>Periodo de Vacaciones:   {{ scope.row.vacationPeriod }}</span><br>
          <span>Fecha de Nacimiento:   {{ scope.row.birthDate }}</span><br>
          <span>Fecha Ingreso:  {{ scope.row.dateAntiquity }}</span><br>
          <span>ID Puesto:   {{ scope.row.idJob }}</span><br>
          <span>ID Unidad:   {{ scope.row.idUnit }}</span><br>
          <span>Unidad:   {{ scope.row.unit }}</span><br>
          <span>ID Equipo:   {{ scope.row.idTeam }}</span><br>
          <span>ID Lugar de trabajo:   {{ scope.row.idWorkPlace }}</span><br>
          <span>Lugar de Trabajo:   {{ scope.row.workPlace }}</span><br>
          <span>ID región:   {{ scope.row.idRegion }}</span><br>
          <span>Región:  {{ scope.row.region }}</span><br>
          <span>Estatus:  {{ scope.row.status?'Activo':'Inactivo' }}</span><br>
          <span>Tipo de Registro:  {{ scope.row.isManual?'Manual':'Meta4' }}</span><br>
          <span>Actualizado:  {{ scope.row.updated_at | formatDateTime }}</span><br>
          <span>Registro:  {{ scope.row.created_at | formatDateTime }}</span><br>
          <span>Contactos de emergencia:</span><br>
          <span>Nombre:  {{ scope.row.emergencyContactName }}</span><br>
          <span>Teléfono:  {{ scope.row.emergencyContactPhone }}</span><br>
          <span>Audio:  {{ scope.row.nameAudio }}</span><br>

        </template>
      </el-table-column>
      <el-table-column label="Usuario ID"  width="120" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.idEmployed }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Nombre" width="240" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.name }}</span>
        </template>

      </el-table-column>
       <el-table-column label="Puesto" width="120" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.job }}</span>
        </template>

      </el-table-column>
      <el-table-column label="Telefono" width="120" align="center">
         <template slot-scope="scope" >
          <span>{{ scope.row.phoneCelular }}</span>
        </template>

      </el-table-column>

      <el-table-column label="Audio" width="100" align="center">
         <template slot-scope="scope" >
          <span>{{ scope.row.nameAudio?'Si':'No' }}</span>
        </template>
      </el-table-column>

      <el-table-column label="Fecha Antiguedad" width="150" align="center">
        <template slot-scope="scope" >
          <span>{{ scope.row.dateAntiquity | formatDateTime }}</span>
        </template>

      </el-table-column>

      <el-table-column label="Fecha Nacimiento" width="150" align="center">
        <template slot-scope="scope" >
          <span>{{ scope.row.birthDate  | formatDate }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Reproducir" align="center" width="90">
          <template slot-scope="scope" >
             <el-button
              @click.stop.prevent="playAudioFile(scope.$index, scope.row)"
              size="small"
              type="primary"
              circle
              icon="el-icon-video-play"
              :disabled="scope.row.nameAudio== null || scope.row.nameAudio == ''? true : false"
            ></el-button>
          </template>
      </el-table-column>
      <el-table-column label="Operación" width="250" align="center">
          <template slot-scope="scope" >
            <el-button
              @click.stop.prevent="handleInclude(scope.$index, scope.row)"
              size="mini"
              type="primary"
              round
            >Incluir <i class="el-icon-arrow-right el-icon-circle-plus-outline"></i></el-button>
            <el-button
              @click.stop.prevent="handleExclude(scope.$index, scope.row)"
              size="mini"
              type="danger"
              round
            >Excluir <i class="el-icon-arrow-right el-icon-remove-outline"></i></el-button>
            <!-- <span>{{ scope.row.deviceLoggersLast }}</span> -->
          </template>
        </el-table-column>
    </el-table>
    <el-row type="flex" justify="end">
        <el-pagination @size-change="handleChangeItemPage"  :page-sizes="[25, 50, 100, 200]"  :page-size="25" layout="total,sizes,prev, pager, next" @current-change="handleCurrentChange"
          :total="parseInt(totalPages)">
        </el-pagination>
    </el-row>
  </div>
  <div v-if="active===1">
    <el-row>
      <el-col :sm="24" :md="11" >
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>Crear mensaje</span>
          </div>
          <div class="text item">
            <ValidationObserver ref="formMessages" >
              <el-form label-position="top" label-width="100px" :model="filterForm" >
                  <el-form-item label="Título" size="mini">
                    <ValidationProvider name="Título" rules="required" v-slot="{ errors }">
                      <el-input v-model="filterForm.title"></el-input>
                      <span>{{ errors[0] }}</span>
                    </ValidationProvider>
                  </el-form-item>
                  <!-- <el-form-item label="Descripción" size="mini">
                    <ValidationProvider name="descripción" rules="required|max:155" v-slot="{ errors }">
                      <el-input v-model="filterForm.description"></el-input>
                      <span>{{ errors[0] }}</span>
                    </ValidationProvider>
                  </el-form-item> -->
                  <el-form-item label="¿Tu mensaje es para felicitar cumpleaños?">
                    <el-switch
                      v-model="filterForm.isBirthDate"
                      active-text="Si"
                      inactive-text="No">
                     </el-switch>
                   </el-form-item>
                   <div v-if="filterForm.isBirthDate === false">
                     <el-form-item label="Fecha de publicación">
                        <ValidationProvider name="Fecha de publicación" rules="required" v-slot="{ errors }">
                          <el-date-picker
                            size="mini"
                            v-model="filterForm.schedulingDateTime"
                            type="datetime"
                            placeholder="Fecha"
                            default-time="12:00:00">
                          </el-date-picker>
                          <span>{{ errors[0] }}</span>
                        </ValidationProvider>
                      </el-form-item>
                   </div>
              </el-form>
            </ValidationObserver>
          </div>
        </el-card>
      </el-col>
      <el-col :sm="24" :md="12" :offset="1" >
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>Resumen</span>
          </div>
          <div class="items-files">
             <span v-if="filterForm.filterTypeMessage">
              Tipo de mensaje: {{filterForm.filterTypeMessage== 'Personalized'?'Personalizado':'Generico'}}
              <br><br>
            </span>
            <span>Su mensaje será enviando a:<br></span>
            <span v-if="filterForm.filterWorkplace">
              ID Lugar de trabajo: {{filterForm.filterWorkplace}}
              <br>
            </span>
            <span v-if="filterForm.filterUnit">
              ID Unidad de trabajo: {{filterForm.filterUnit}}
              <br>
            </span>
            <span v-if="filterForm.filterJob">
              ID Puesto: {{filterForm.filterJob}}
              <br>
            </span>
            <span v-if="filterForm.filterTeam">
              ID Dirección: {{filterForm.filterTeam}}
            </span>
            <span v-if="filterForm.onlyListIncludedUser">
              - Usuarios incluidos en la tabla manualmente
            </span>
          </div>
        </el-card>
      </el-col>
      <el-col :sm="24" :md="12" :offset="1" >
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>Cargar audio</span>
            <div slot="tip" class="el-upload__tip">Es necesario ordenar los audios</div>
          </div>
          <div class="items-files">
            <Draggable :list="filterForm.audioFiles">
              <li v-for="audioFile in filterForm.audioFiles"
                  :key="audioFile.id">
                  <div style="cursor: move" class="">
                      <span>{{ audioFile.name }}</span>
                      <el-button
                      size="mini"
                      type="text"
                      round
                      @click="deleteFile(audioFile)">Quitar</el-button>
                  </div>
                  <!-- <template slot-scope="scope"> -->

                  <!-- </template> -->
              </li>
            </Draggable>
            <el-upload
              name="file"
              action="#"
              :before-upload="beforeAvatarUpload"
              :http-request="postAudio"
              :on-success="handleAvatarSuccessAudio"
              :show-file-list="false"
              multiple>
              <el-button slot="trigger" size="small" :loading="loading" type="primary">Selecciona un audio</el-button>
              <div slot="tip" class="el-upload__tip">Solo archivos .mp3 con un tamaño menor de 2 MB</div>

            </el-upload><br>
            <span>¿Desea hacer una prueba?</span><br>
            <el-switch v-model="filterForm.activePreview">
            </el-switch>
          </div>
        </el-card>
      </el-col>
      <div v-if="filterForm.activePreview">
        <el-col :sm="24" :md="12" :offset="12" >
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>Prueba de mensage de audio</span>
            </div>
            <div class="items-files">
              <ValidationObserver v-slot="{ invalid }">
              <el-form label-position="top" label-width="100px" :model="filterForm">
                <el-form-item label="Id usuario" size="mini">
                  <ValidationProvider name="id usuario" rules="required" v-slot="{ errors }">
                    <el-input v-model="filterForm.idEmployed" placeholder="1234"></el-input>
                    <span>{{ errors[0] }}</span>
                  </ValidationProvider>
                </el-form-item>
                <el-form-item >
                <el-button
                  @click.stop.prevent="onSendPreview"
                  size="mini"
                  type="primary"
                  :disabled="invalid"
                  :loading="loading"
                  icon="el-icon-upload2">
                  Preview</el-button>
                </el-form-item>
              </el-form>
              </ValidationObserver>
            </div>
          </el-card>
        </el-col>
      </div>
    </el-row>
    <el-row>
      <el-col :span="3" :offset="5">
        <el-button
          @click.stop.prevent="goBack"
          size="mini"
          type="primary"
          icon="el-icon-back"
        >Regresar </el-button>
      </el-col>
      <el-col :span="3" :offset="9">
        <el-button
          @click.stop.prevent="goNext"
          size="mini"
          type="primary"
          :loading="loading"
        >Guardar <i class="el-icon-arrow-right el-icon-right"></i></el-button>
        <!-- <el-button
          @click.stop.prevent="onStore"
          size="mini"
          type="primary"
          :loading="loading"
        >Guardar <i class="el-icon-arrow-right el-icon-right"></i></el-button> -->
      </el-col>
    </el-row>
  </div>
</div>
</template>
<script>
import { filterUser, getPageFilter, store, searchUser } from '@/api/messageGroup'
import { getWorkplace, getUnitplace, getTeamid, getJobid, playAudio } from '@/api/user'
import { preview } from '@/api/messages'
import { uploadAudio } from '@/api/fileUpload'

// import draggable from './plugins/vuedraggable'
import Draggable from 'vuedraggable'

export default {
  components: {
    Draggable
  },
  data () {
    return {
      filterForm: {
        filterWorkplace: '',
        filterUnit: '',
        filterTeam: '',
        filterJob: '',
        filterBirthDate: '',
        filterBirthDateStartRange: '',
        filterBirthDateEndRange: '',
        filterDateAntiquity: '',
        filterDateAntiquityStartRange: '',
        filterDateAntiquityEndRange: '',
        filterVacationDaysCountOperator: '',
        filterVacationDaysCount: '',
        filterVacationPeriodCountOperator: '',
        filterVacationPeriodCount: '',
        filterIncludeUsersId: [],
        filterExcludeUsersId: [],
        isBirthDate: false,
        onlyListIncludedUser: false,
        filterTypeMessage: 'Personalized',
        // Preview
        activePreview: false,
        idEmployed: '',
        // Form
        title: '',
        schedulingDateTime: '',
        nameaudio: '',
        audioFiles: [
          {
            name: '[+name+]',
            audio: '[+name+]'
          }
        ]
      },
      searchForm: {
        query: ''
      },
      list: null,
      totalPages: '',
      currentPage: '',
      loading: false,
      active: 0,
      filterDateAntiquity: [],
      filterBirthDate: [],
      listWorkPlace: [],
      listUnitPlace: [],
      listTeamid: [],
      listJob: [],
      filterOperador: [{
        value: '>=',
        label: '>=',
        title: 'Mayor o igual'
      }, {
        value: '=',
        label: '=',
        title: 'Igual'
      }, {
        value: '<=',
        label: '<=',
        title: 'Menor o igual'
      }, {
        value: '<>',
        label: '<>',
        title: 'Diferente de'
      }],
      typeMessageOptions: [{
        value: 'Personalized',
        label: 'Personalizado'
      }, {
        value: 'Generic',
        label: 'Generico'
      }],
      pickerOptions: {
        shortcuts: [{
          text: 'Semana pasada',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: 'Mes pasado',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '3 Meses atrás',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '6 Meses atrás',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 180)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '9 Meses atrás',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 270)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '1 Año atrás',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365)
            picker.$emit('pick', [start, end])
          }
        }]
      }
    }
  },
  mounted () {
    this.listWorkPlaceTitle()
    this.listUnitPlaceTitle()
    this.listTeamIdTitle()
    this.listJobTitle()
    this.handleSearchFilter()
  },
  methods: {
    async onStore () {
      // console.log(this.filterForm)
      this.loading = true
      await store(this.filterForm)
        .then(() => {
          this.$message({
            showClose: true,
            message: 'Sea creado el grupo correctamente!',
            type: 'success'
          })
          this.$router.push({ name: 'messages' })
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async onSendPreview () {
      console.log(this.filterForm)
      this.loading = true
      await preview(this.filterForm)
        .then(() => {
          this.$message({
            showClose: true,
            message: 'Se envio el mensaje correctamente!',
            type: 'success'
          })
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async goNext () {
      if (this.active === 0) {
        if (this.filterForm.filterTypeMessage === 'Generic') {
          this.deleteFile(0)
        } else if (this.filterForm.filterTypeMessage === 'Personalized') {
          const EXIST_ITEM = this.filterForm.audioFiles.find(e => e.audio === '[+name+]')

          if (EXIST_ITEM === undefined) {
            this.filterForm.audioFiles.push({
              name: '[+name+]',
              audio: '[+name+]'
            })
          }
        }
        return this.active++
      } else if (this.active === 1) {
        const val = await this.$refs.formMessages.validate((valid) => {})
        if (val) {
          this.onStore()
        }
      }
    },
    goBack () {
      if (this.active === 2) {
        return this.active--
      } else if (this.active === 1) {
        return this.active--
      } else if (this.active === 0) {
        this.$router.push({ name: 'messages' })
      }
    },
    async handleCurrentChange (page) {
      this.currentPage = page
      this.loading = true
      await getPageFilter(page, this.showItempageValue)
        .then(response => {
          this.list = response.data.data
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleChangeItemPage (val) {
      this.loading = true
      await getPageFilter(this.currentPage, val)
        .then(response => {
          this.list = response.data.data
          this.totalPages = response.data.total
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleSearchFilter () {
      this.loading = true
      await filterUser(this.filterForm)
        .then(response => {
          this.list = response.data.data
          this.totalPages = response.data.total
          // console.log(response.data)
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleSearch () {
      this.loading = true
      const formData = new FormData()
      formData.append('query', this.searchForm.query)
      formData.append('filterTypeMessage', this.filterForm.filterTypeMessage)
      // console.log(formData)
      await searchUser(formData)
        .then(response => {
          this.list = response.data.data
          this.totalPages = response.data.total
          // console.log(response.data)
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    // handleChange (file) {
    //   console.log(file)
    //   this.filterForm.audioFiles.push(file)
    // },
    deleteFile (file) {
      // console.log(file)
      const index = this.filterForm.audioFiles.indexOf(file)
      this.filterForm.audioFiles.splice(index, 1)
      // console.log(this.filterForm.audioFiles)
    },
    handleInclude (index, row) {
      const EMAIL_INCLUDE = this.filterForm.filterIncludeUsersId.find(e => e.email === row.email)
      const EMAIL_EXCLUDE = this.filterForm.filterExcludeUsersId.find(e => e.email === row.email)

      if (EMAIL_INCLUDE === undefined && EMAIL_EXCLUDE === undefined) {
        this.filterForm.filterIncludeUsersId.push({
          email: row.email,
          _id: row._id
        })
      } else {
        this.$message.warning(`El Usuario ${row.email} ya esta agregado.`)
      }
      // this.list.splice(index, 1)
    },
    handleExclude (index, row) {
      const EMAIL_EXCLUDE = this.filterForm.filterExcludeUsersId.find(e => e.email === row.email)
      const EMAIL_INCLUDE = this.filterForm.filterIncludeUsersId.find(e => e.email === row.email)

      if (EMAIL_EXCLUDE === undefined && EMAIL_INCLUDE === undefined) {
        this.filterForm.filterExcludeUsersId.push({
          email: row.email,
          _id: row._id
        })
      } else {
        this.$message.warning(`El Usuario ${row.email} ya esta agregado.`)
      }
      // this.list.splice(index, 1)
    },
    handleDeleteIncluder (index, row) {
      // console.log(index, row)
      this.filterForm.filterIncludeUsersId.splice(index, 1)
    },
    handleDeleteExcluder (index, row) {
      // console.log(index, row)
      this.filterForm.filterExcludeUsersId.splice(index, 1)
    },
    handleFilterDateAntiquity () {
      if (this.filterDateAntiquity) {
        this.filterForm.filterDateAntiquityStartRange = this.filterDateAntiquity[0]
        this.filterForm.filterDateAntiquityEndRange = this.filterDateAntiquity[1]
        this.handleSearchFilter()
      } else {
        this.filterForm.filterDateAntiquityStartRange = ''
        this.filterForm.filterDateAntiquityEndRange = ''
        this.handleSearchFilter()
      }
    },
    handleFilterBirthDate () {
      if (this.filterBirthDate) {
        this.filterForm.filterBirthDateStartRange = this.filterBirthDate[0]
        this.filterForm.filterBirthDateEndRange = this.filterBirthDate[1]
        this.handleSearchFilter()
      } else {
        this.filterForm.filterBirthDateStartRange = ''
        this.filterForm.filterBirthDateEndRange = ''
        this.handleSearchFilter()
      }
    },
    handleClean () {
      this.filterForm.filterWorkplace = ''
      this.filterForm.filterUnit = ''
      this.filterForm.filterTeam = ''
      this.filterForm.filterJob = ''
      this.filterForm.filterBirthDateStartRange = ''
      this.filterForm.filterBirthDateEndRange = ''
      this.filterForm.filterBirthDate = ''
      this.filterForm.filterDateAntiquity = ''
      this.filterForm.filterDateAntiquityStartRange = ''
      this.filterForm.filterDateAntiquityEndRange = ''
      this.filterForm.filterVacationDaysCountOperator = ''
      this.filterForm.filterVacationDaysCount = ''
      this.filterForm.filterVacationPeriodCountOperator = ''
      this.filterForm.filterVacationPeriodCount = ''
      this.filterForm.filterIncludeUsersId = []
      this.filterForm.filterExcludeUsersId = []
      this.searchForm.query = ''
      this.handleSearchFilter()
    },
    handleCleanSearch () {
      this.searchForm.query = ''
      this.handleSearchFilter()
    },
    async listUnitPlaceTitle () {
      this.loading = true
      await getUnitplace()
        .then(response => {
          // console.log(response)
          this.listUnitPlace = response.data
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async listTeamIdTitle () {
      this.loading = true
      await getTeamid()
        .then(response => {
          console.log(response)
          this.listTeamid = response.data
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    // Jalar Lista de Trabajos
    async listWorkPlaceTitle () {
      this.loading = true
      await getWorkplace()
        .then(response => {
          // console.log(response)
          this.listWorkPlace = response.data
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async listJobTitle () {
      this.loading = true
      await getJobid()
        .then(response => {
          // console.log(response)
          this.listJob = response.data
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async postAudio (param) {
      this.loading = true
      const formData = new FormData()
      formData.append('file', param.file)
      formData.append('folder', 'audios/templates')
      uploadAudio(formData).then((response) => {
        // console.log(response)
        param.onSuccess(response)
      })
        .catch(response => {
          console.log(response)
          param.onError()
        })
        .finally(() => (this.loading = false))
    },
    handleAvatarSuccessAudio (res, file) {
      console.log(file.response.path)
      this.filterForm.audioFiles.push({
        name: file.name,
        audio: file.response.path
      })
      // console.log(this.filterForm)
    },
    beforeAvatarUpload (file) {
      // console.log(file)
      const isMP3 = file.type === 'audio/mpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isMP3) {
        this.$message.error('¡El audio debe estar en formato mp3!')
      }
      if (!isLt2M) {
        this.$message.error('¡El audio excede los 2MB!')
      }
      return isMP3 && isLt2M
    },
    async playAudioFile (index, row) {
      // this.loading = true
      this.audioCtx = new (window.AudioContext || window.webkitAudioContext)()
      await playAudio({ file: row.nameAudio })
        .then((response) => {
          var audio = new Audio()
          audio.src = window.URL.createObjectURL(new Blob([response]))
          this.fuenteDeReproduccion = this.audioCtx.createMediaElementSource(audio)
          this.fuenteDeReproduccion.connect(this.audioCtx.destination)
          audio.play()
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    }
  }
}
</script>
